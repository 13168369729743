import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'

const Page = () => {
    return (
        <Layout>
            <SEO title="Gestire un Call Center o Contact center? - Thelgo" description="Thelgo: il software per contact center per il teleselling, telemarketing che permette all'utente di caricare liste di contatti in modo autonomo e semplice" />
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="flex gap-12 flex-wrap-reverse items-center">
                        <div className="lg:text-right flex-1">
                            <span className="tag">Scenari di utilizzo</span>
                            <h1 className="text-4xl lg:text-5xl">Vuoi gestire un contact center?</h1>
                            <h2 className="text-4xl lg:text-5xl text-persian-green-500 mb-4">Thelgo è il software necessario per aprire un call center o contact center.</h2>
                            <div className="prose-persian-green prose max-w-none mb-8">
                            <p>Thelgo è in grado di gestire campagne telefoniche in cloud, fare Telemarketing, Teleselling, recupero crediti, sondaggi e tanto altro. Il software nelle diverse versioni è integrato con il centralino VOIspeed, è in grado di gestire chiamate inbound e outbound, utilizzare la modalità preview o progressive mode, avere i tempi esatti di ogni conversazione, i tempi per ogni operatore, escludere le chiamate brevi dai report fino ad ottimizzare il numero di chiamate ed aumentare la produttività degli operatori riducendo il tempo di attesa.</p>
                            <p>Con il <b>Predictive Dialing</b>, invece, Thelgo permette di lanciare un numero superiore di chiamate rispetto agli operatori liberi (aumentando così le probabilità di risposta) e allo stesso tempo, permette di gestire la chiamata sia nel caso in cui la telefonata vada a buon fine sia se il numero è occupato o non risposto dando la possibilità di richiamare il contatto con orari diversi.</p>
                        
                            </div>
                            <Link to="/demo" className="button-lg inline-flex text-white bg-persian-green-500 hover:bg-persian-green-600 transition-colors">Richiedi una demo</Link>
                        </div>

                        <div className="w-full lg:w-4/12">
                            <StaticImage src="../images/call-center.png" alt="call center thelgo" className="w-1/4 lg:w-full" placeholder="blurred"/>
                        </div>
                    </div>
                </div>
            </section> 
        </Layout>
    )
}

export default Page